<template>
    <div>
        <store-account-list-create :is-create-store-account-sidebar-active.sync="isCreateStoreAccountSidebarActive"
                                   :store-type="storeType" @refresh-data="refreshData"/>

        <store-account-list-edit :is-edit-store-account-sidebar-active.sync="isEditStoreAccountSidebarActive"
                                 :editStoreAccountData="editStoreAccountData"
                                 :store-type="storeType" @refresh-data="refreshData"/>

        <b-tabs vertical content-class="col-12 col-md-9 mt-1 mt-md-0" pills nav-wrapper-class="col-md-3 col-12"
                nav-class="nav-left">
            <b-tab>
                <template #title>
                    <social-icons socialType="ios" :size="'20'"></social-icons>
                    <span class="font-weight-bold text-capitalize">App Store Accounts</span>
                </template>

                <b-card class="mb-0" id="storeAccount">
                    <template #header>
                        <b-row class="d-flex align-items-center justify-content-end">
                            <b-col class="mb-md-0 mb-2" cols="12" md="8">
                                <h4>App Store Accounts</h4>
                            </b-col>
                            <b-col class="mb-md-0 mb-2" cols="12" md="4"
                                   v-if="$can('read', 'storeAccountCreate')">
                                <b-button class="w-100" variant="primary"
                                          @click="isCreateStoreAccountSidebarActive = true; storeType = 'ios'">
                                    <span>New App Store Account</span>
                                </b-button>
                            </b-col>
                        </b-row>
                    </template>
                    <!--                <setting-list-edit :is-edit-setting-sidebar-active.sync="isEditSettingSidebarActive"-->
                    <!--                                   :editSetting="editSetting" @refetch-data="fetchSettings"></setting-list-edit>-->

                    <b-form>
                        <b-row>
                            <b-col cols="12" v-if="appStoreAccounts">
                                <b-table ref="refIosStoreAccountListTable" class="position-relative"
                                         :items="appStoreAccounts.data"
                                         responsive :fields="['id','email','name','team_id','shared_secret','actions']"
                                         :table-class="'build-list-table table dataTable no-footer dtr-column'"
                                         primary-key="id" show-empty
                                         empty-text="No matching records found">
                                    <template #table-busy>
                                        <div class="text-center my-2">
                                            <b-spinner class="d-block mx-auto mb-50"></b-spinner>
                                            <strong>Loading...</strong>
                                        </div>
                                    </template>
                                    <template #cell(id)="data">
                                        <span class="align-text-top text-capitalize">{{ data.item.id }}</span>
                                    </template>
                                    <template #cell(email)="data">
                                        <span class="align-text-top text-capitalize">{{ data.item.email }}</span>
                                    </template>
                                    <template #cell(name)="data">
                                        <span class="align-text-top text-capitalize">{{ data.item.name }}</span>
                                    </template>
                                    <template #cell(team_id)="data">
                                        <span class="align-text-top text-capitalize">{{ data.item.team_id }}</span>
                                    </template>
                                    <template #cell(shared_secret)="data">
                                        <span class="align-text-top text-capitalize">{{
                                                data.item.shared_secret
                                            }}</span>
                                    </template>
                                    <template #cell(actions)="data">
                                        <b-link class="mr-1" v-b-tooltip.hover.top="'Edit App Store Account'"
                                                @click="fncEditStoreAccountSidebar(data.item.id, 'ios')">
                                            <feather-icon icon="EditIcon" size="18"/>
                                        </b-link>

                                        <b-link class="mr-1" v-b-tooltip.hover.top="'Delete App Store Account'"
                                                v-on:click="confirmDelete(data.item.id, 'App Store Account')">
                                            <feather-icon icon="TrashIcon" size="18"/>
                                        </b-link>
                                    </template>
                                </b-table>

                            </b-col>
                        </b-row>
                    </b-form>
                </b-card>
            </b-tab>

            <b-tab>
                <template #title>
                    <social-icons socialType="android" :size="'20'"></social-icons>
                    <span class="font-weight-bold text-capitalize">Google Play Accounts</span>
                </template>

                <b-card class="mb-0" id="storeAccount">
                    <template #header>
                        <b-row class="d-flex align-items-center justify-content-end">
                            <b-col class="mb-md-0 mb-2" cols="12" md="8">
                                <h4>Google Play Accounts</h4>
                            </b-col>
                            <b-col class="mb-md-0 mb-2" cols="12" md="4"
                                   v-if="$can('read', 'storeAccountCreate')">
                                <b-button class="w-100" variant="primary"
                                          @click="isCreateStoreAccountSidebarActive = true; storeType = 'android'">
                                    <span>New Google Play Account</span>
                                </b-button>
                            </b-col>
                        </b-row>
                    </template>

                    <b-form>
                        <b-row>
                            <b-col cols="12" v-if="androidAccounts">
                                <b-table ref="refAndroidStoreAccountListTable" class="position-relative"
                                         :items="androidAccounts.data"
                                         responsive :fields="['id','email','name','actions']"
                                         :table-class="'build-list-table table dataTable no-footer dtr-column'"
                                         primary-key="id" show-empty
                                         empty-text="No matching records found">
                                    <template #table-busy>
                                        <div class="text-center my-2">
                                            <b-spinner class="d-block mx-auto mb-50"></b-spinner>
                                            <strong>Loading...</strong>
                                        </div>
                                    </template>
                                    <template #cell(id)="data">
                                        <span class="align-text-top text-capitalize">{{ data.item.id }}</span>
                                    </template>
                                    <template #cell(email)="data">
                                        <span class="align-text-top text-capitalize">{{ data.item.email }}</span>
                                    </template>
                                    <template #cell(name)="data">
                                        <span class="align-text-top text-capitalize">{{ data.item.name }}</span>
                                    </template>
                                    <template #cell(actions)="data">
                                        <b-link class="mr-1" v-b-tooltip.hover.top="'Edit Google Play Account'"
                                                @click="fncEditStoreAccountSidebar(data.item.id, 'android')">
                                            <feather-icon icon="EditIcon" size="18"/>
                                        </b-link>
                                        <b-link class="mr-1" v-b-tooltip.hover.top="'Delete Google Play Account'"
                                                v-on:click="confirmDelete(data.item.id, 'Google Play Account')">
                                            <feather-icon icon="TrashIcon" size="18"/>
                                        </b-link>
                                    </template>
                                </b-table>
                            </b-col>
                        </b-row>
                    </b-form>
                </b-card>
            </b-tab>

        </b-tabs>
    </div>
</template>
<script>
import {
    BTabs,
    BTab,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BFormTextarea,
    BFormInvalidFeedback,
    BTable,
    VBTooltip,
    BLink
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import useStoreAccountApi from '@/composables/useStoreAccountApi'
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import {required} from '@validations'
import SocialIcons from "@/components/SocialIcons";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import {ref} from "@vue/composition-api";
import StoreAccountListCreate from "@/views/storeAccount/StoreAccountListCreate";
import StoreAccountListEdit from "@/views/storeAccount/StoreAccountListEdit";

export default {
    components: {
        SocialIcons,
        StoreAccountListCreate,
        StoreAccountListEdit,
        BTabs,
        BTab,
        BButton,
        BForm,
        BFormGroup,
        BFormInput,
        BRow,
        BCol,
        BCard,
        BFormTextarea,
        vSelect,
        ValidationObserver,
        ValidationProvider,
        BFormInvalidFeedback,
        BTable,
        VBTooltip,
        BLink,
        useStoreAccountApi
    },
    directives: {
        'b-tooltip': VBTooltip,
        Ripple,
    },
    methods: {
        fncEditStoreAccountSidebar(id, type) {
            this.isEditStoreAccountSidebarActive = true;
            this.storeType = type
            if (type === 'ios') {
                this.$store.dispatch('storeAccountStore/fetchAppstoreAccount', {id: id}).then((response) => {
                    const {data} = response.data
                    this.editStoreAccountData = data
                })
            } else {
                this.$store.dispatch('storeAccountStore/fetchAndroidAccount', {id: id}).then((response) => {
                    const {data} = response.data
                    this.editStoreAccountData = data
                })
            }
        },
        confirmDelete(id, type) {
            this.$swal.fire({
                title: 'Are you sure?',
                text: "Are you sure you want to delete the " + type + "?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, delete!',
                customClass: {
                    confirmButton: 'btn btn-primary mr-1',
                    cancelButton: 'btn btn-outline-primary ms-1'
                },
                buttonsStyling: false
            }).then(result => {
                if (result.value) {
                    if (type === 'Google Play Account') {
                        this.$store.dispatch('storeAccountStore/deleteAndroidAccount', {
                            id: id
                        }).then(() => {
                            this.$toast({
                                component: ToastificationContent,
                                props: {
                                    title: 'Successfully deleted!',
                                    icon: 'CheckIcon',
                                    variant: 'success',
                                },
                            })
                        }).then(() => {
                            this.refreshData()
                        })
                    } else {
                        this.$store.dispatch('storeAccountStore/deleteAppstoreAccount', {
                            id: id
                        }).then(() => {
                            this.$toast({
                                component: ToastificationContent,
                                props: {
                                    title: 'Successfully deleted!',
                                    icon: 'CheckIcon',
                                    variant: 'success',
                                },
                            })
                        }).then(() => {
                            this.refreshData()
                        })
                    }
                }
            })
        }
    },
    data() {
        return {
            loading: false,
            required
        }
    },
    setup() {
        const isCreateStoreAccountSidebarActive = ref(false)
        const isEditStoreAccountSidebarActive = ref(false)
        const storeType = ref(false)
        const editStoreAccountData = ref(false)
        const {
            toast,
            isLoading,
            refreshData,
            appStoreAccounts,
            androidAccounts,
            refIosStoreAccountListTable,
            refAndroidStoreAccountListTable,
            fetchAndroidAccounts,
            fetchAppstoreAccounts
        } = useStoreAccountApi()
        return {
            toast,
            isLoading,
            isCreateStoreAccountSidebarActive,
            isEditStoreAccountSidebarActive,
            storeType,

            refreshData,
            editStoreAccountData,
            refIosStoreAccountListTable,
            refAndroidStoreAccountListTable,
            appStoreAccounts,
            androidAccounts,
            fetchAndroidAccounts,
            fetchAppstoreAccounts
        }
    },
    mounted() {
        this.fetchAndroidAccounts()
        this.fetchAppstoreAccounts()
    },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.card-title {
    text-transform: capitalize;
}

#storeAccount .card-header {
    width: 100%;
    display: block;
}

.nav-pills .nav-link.active {
    background-color: #fff;
    color: #5e5873;
}
</style>

<template>
    <b-sidebar id="edit-store-account-sidebar" :visible="isEditStoreAccountSidebarActive" bg-variant="white"
               sidebar-class="sidebar-lg" shadow backdrop no-header right
               @change="(val) => $emit('update:is-edit-store-account-sidebar-active', val)">
        <template #default="{ hide }">
            <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
                <h5 class="mb-0">Edit Store Account</h5>
                <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide"/>
            </div>
            <validation-observer v-slot="{ handleSubmit }" ref="refFormObserver" v-if="editStoreAccountData">
                <b-form ref="form" class="p-2" @submit.prevent="handleSubmit(onSubmit)">
                    <validation-provider #default="{ errors }" name="Name" rules="required">
                        <b-form-group label="Name" label-for="name">
                            <b-form-input id="name" v-model="editStoreAccountData.name" autofocus trim
                                          :state="errors.length > 0 ? false:null" placeholder="John Doe"/>
                        </b-form-group>
                    </validation-provider>
                    <validation-provider #default="{ errors }" name="Email" rules="required|email">
                        <b-form-group label="Email" label-for="email">
                            <b-form-input id="email" v-model="editStoreAccountData.email"
                                          :state="errors.length > 0 ? false:null" placeholder="example@gameguru.net"
                                          trim/>
                        </b-form-group>
                    </validation-provider>
                    <div v-if="storeType === 'ios'">
                        <validation-provider #default="{ errors }" name="Team Id">
                            <b-form-group label="Team Id" label-for="Team Id">
                                <b-form-input id="team_id" v-model="editStoreAccountData.team_id" trim
                                              :state="errors.length > 0 ? false:null" placeholder="John Doe"/>
                            </b-form-group>
                        </validation-provider>
                        <validation-provider #default="{ errors }" name="Api Json">
                            <b-form-group label="Api Json" label-for="Api Json">
                                <b-form-textarea
                                    id="api_json"
                                    v-model="editStoreAccountData.api_json"
                                    :state="errors.length > 0 ? false:null"
                                    rows="3"
                                    max-rows="6"
                                ></b-form-textarea>
                            </b-form-group>
                        </validation-provider>
                        <validation-provider #default="{ errors }" name="Shared Secret">
                            <b-form-group label="Shared Secret" label-for="Shared Secret">
                                <b-form-input id="shared_secret" v-model="editStoreAccountData.shared_secret"
                                              :state="errors.length > 0 ? false:null"
                                              trim/>
                            </b-form-group>
                        </validation-provider>
                    </div>
                    <div v-if="storeType === 'android'">
                        <validation-provider #default="{ errors }" name="ServiceAccount">
                            <b-form-group label="Service Account" label-for="Service Account">
                                <b-form-textarea id="textarea"
                                                 v-model="editStoreAccountData.service_account"
                                                 :state="errors.length > 0 ? false:null" rows="3" max-rows="6"
                                ></b-form-textarea>
                            </b-form-group>
                        </validation-provider>
                    </div>
                    <validation-provider #default="{ errors }" name="Applovin Management Key" rules="required">
                            <b-form-group label="Applovin Management Key" label-for="applovin-management-key">
                                <b-form-input id="applovin-sdk-key" v-model="editStoreAccountData.applovin_management_key" trim
                                              :state="errors.length > 0 ? false:null" placeholder="Applovin Management Key"/>
                            </b-form-group>
                        </validation-provider>
                    <validation-provider #default="{ errors }" name="Applovin Sdk Key" rules="required">
                        <b-form-group label="Applovin Sdk Key" label-for="applovin-sdk-key">
                            <b-form-input id="applovin-sdk-key" v-model="editStoreAccountData.applovin_sdk_key" trim
                                              :state="errors.length > 0 ? false:null" placeholder="Applovin Sdk Key"/>
                        </b-form-group>
                    </validation-provider>
                    <div class="d-flex mt-2">
                        <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-2"
                                  type="submit">
                            Update
                        </b-button>
                        <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="button"
                                  variant="outline-secondary"
                                  @click="hide">
                            Cancel
                        </b-button>
                    </div>
                </b-form>
            </validation-observer>
        </template>
    </b-sidebar>
</template>

<script>
import {
    BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BFormTextarea
} from 'bootstrap-vue'
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import {required, email} from '@validations'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useStoreAccountApi from '@/composables/useStoreAccountApi'
import formValidation from "@core/comp-functions/forms/form-validation";

export default {
    components: {
        BSidebar,
        BForm,
        BFormGroup,
        BFormInput,
        BFormTextarea,
        BFormInvalidFeedback,
        BButton,
        vSelect,
        ToastificationContent,
        ValidationProvider,
        ValidationObserver,
    },
    directives: {
        Ripple,
    },
    model: {
        prop: 'isEditStoreAccountSidebarActive',
        event: 'update:is-edit-store-account-sidebar-active',
    },
    props: {
        isEditStoreAccountSidebarActive: {
            type: Boolean,
            required: true,
        },
        storeType: {},
        editStoreAccountData: {}
    },
    methods: {
        onSubmit() {
            if (this.storeType === 'ios') {
                this.$store.dispatch('storeAccountStore/updateAppstoreAccount', {
                    id: this.editStoreAccountData.id,
                    email: this.editStoreAccountData.email,
                    name: this.editStoreAccountData.name,
                    team_id: this.editStoreAccountData.team_id,
                    api_json: this.editStoreAccountData.api_json,
                    shared_secret: this.editStoreAccountData.shared_secret,
                    applovin_sdk_key:this.editStoreAccountData.applovin_sdk_key,
                    applovin_management_key:this.editStoreAccountData.applovin_management_key,
                }).then(() => {
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Successfully edited!',
                            icon: 'CheckIcon',
                            variant: 'success',
                        },
                    });
                }).then(() => {
                    this.$emit('refresh-data')
                    this.editStoreAccountData = {}
                    this.$emit('update:is-edit-store-account-sidebar-active', false)
                }).catch((errors) => {
                    console.log(errors)
                })
            } else {
                this.$store.dispatch('storeAccountStore/updateAndroidAccount', {
                    id: this.editStoreAccountData.id,
                    email: this.editStoreAccountData.email,
                    name: this.editStoreAccountData.name,
                    service_account: this.editStoreAccountData.service_account,
                    applovin_sdk_key:this.editStoreAccountData.applovin_sdk_key,
                    applovin_management_key:this.editStoreAccountData.applovin_management_key,
                }).then(() => {
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Successfully edited!',
                            icon: 'CheckIcon',
                            variant: 'success',
                        },
                    });
                }).then(() => {
                    this.$emit('refresh-data')
                    this.editStoreAccountData = {}
                    this.$emit('update:is-edit-store-account-sidebar-active', false)
                }).catch((errors) => {
                    console.log(errors)
                })
            }
        }
    },
    data() {
        return {
            required,
            email,
        }
    },
    setup() {
        const {
            fetchAndroidAccounts,
            fetchAppstoreAccounts,
        } = useStoreAccountApi()

        const {
            refFormObserver,
            getValidationState,
            resetForm,
        } = formValidation()

        return {
            refFormObserver,
            getValidationState,
            resetForm,

            fetchAndroidAccounts,
            fetchAppstoreAccounts
        }
    },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
